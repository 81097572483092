





























import { Component, Vue } from 'vue-property-decorator'
import ThingImage from '@/components/ThingImage/ThingImage.vue'
import parseThing from '@/ts/parseThing'

interface Thing {
  id: number;
  name: string;
  description: string;
  dna: number;
  size: number;
  symmetry: number;
  latitude: number;
  longitude: number;
  minter: string;
  createdAt: number;
}

@Component({ components: { ThingImage } })
export default class Gallery extends Vue {
  things: Array<Thing> = []
  maxItemsPerPage = 25
  nextPageToLoad = 0
  pagesLoading: boolean[] = []
  totalSupply = 0

  async mounted (): Promise<void> {
    this.loadMore()
  }

  async loadMore (): Promise<void> {
    const things = await this.loadThings(this.nextPageToLoad)
    this.things.push(...things)
    this.nextPageToLoad++
  }

  async loadThings (pageNumber: number): Promise<Thing[]> {
    this.$set(this.pagesLoading, pageNumber, true)

    const contract = await this.$store.getters['blockchain/contract']
    this.totalSupply = await contract.totalSupply()
    const things = []
    for (let tokenIndex = this.maxItemsPerPage * pageNumber; tokenIndex < this.maxItemsPerPage * (pageNumber + 1); tokenIndex++) {
      if (tokenIndex >= this.totalSupply) break
      const tokenId = await contract.tokenByIndex(tokenIndex)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      things.push(contract.getThing(tokenId).then((token: any) => {
        const thing = parseThing(parseInt(token.seed._hex))
        return {
          id: parseInt(token.id._hex),
          name: token.name,
          description: token.description,
          dna: thing.dna,
          size: thing.size,
          symmetry: thing.symmetry,
          latitude: thing.latitude,
          longitude: thing.longitude,
          minter: token.minter,
          createdAt: parseInt(token.createdAt._hex)
        }
      }))
    }

    Promise.all(things).then(() => { this.$set(this.pagesLoading, pageNumber, false) })
    return Promise.all(things)
  }

  get isLoading (): boolean {
    return this.pagesLoading.filter(l => l).length > 0
  }
}
